import { paymentMethods } from '../../../../assets/helpers/options';
import { PaymentMethodCard } from '../LandingComponents/LandingComponents';
import authService from '../../../../services/authService';
import { useParams } from 'react-router-dom';

export const PaymentMethodsComponent = (props) => {
  const { selectPaymentMethod, paymentNumber, } = props;

  const { saleChannel } = useParams();

  return (
    <>
      <div className="membership-buttons-container">
        <PaymentMethodCard
          method={{ name: paymentMethods?.TDC }}
          openModal={() =>
            selectPaymentMethod(paymentMethods?.TDC, paymentNumber)
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.ZELLE }}
          openModal={() =>
            selectPaymentMethod(paymentMethods?.ZELLE, paymentNumber)
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.TRANSFERENCIA_BANCAMIGA }}
          openModal={() =>
            selectPaymentMethod(
              paymentMethods?.TRANSFERENCIA_BANCAMIGA,
              paymentNumber
            )
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.PAGO_MÓVIL_BANCAMIGA }}
          openModal={() =>
            selectPaymentMethod(
              paymentMethods?.PAGO_MÓVIL_BANCAMIGA,
              paymentNumber
            )
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.PAGO_MÓVIL_BNC }}
          openModal={() =>
            selectPaymentMethod(
              paymentMethods?.PAGO_MÓVIL_BNC,
              paymentNumber
            )
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.PAYPAL }}
          openModal={() =>
            selectPaymentMethod(paymentMethods?.PAYPAL, paymentNumber)
          }
        />
        <PaymentMethodCard
          method={{ name: paymentMethods?.BINANCE }}
          openModal={() =>
            selectPaymentMethod(paymentMethods?.BINANCE, paymentNumber)
          }
        />
        {authService.getUserType() && paymentNumber !== 2 && (
          <PaymentMethodCard
            method={{ name: paymentMethods?.CASH_USD }}
            openModal={() =>
              selectPaymentMethod(paymentMethods?.CASH_USD, paymentNumber)
            }
          />
        )}

        {authService.getUserType() && (
          <PaymentMethodCard
            method={{ name: paymentMethods?.PUNTO_DE_VENTA }}
            openModal={() =>
              selectPaymentMethod(paymentMethods?.PUNTO_DE_VENTA, paymentNumber)
            }
          />
        )}
        {!authService.getUserType() && saleChannel && (
          <PaymentMethodCard
            method={{ name: paymentMethods?.PAGO_EN_COMERCIO }}
            openModal={() =>
              selectPaymentMethod(paymentMethods?.PAGO_EN_COMERCIO, paymentNumber)
            }
          />
        )}

      </div>
    </>
  );
};
