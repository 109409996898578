import {
  providerStatus,
  paymentMethods as paymentMethodsEnum,
} from '../helpers/options'
import { notify } from './toast'
import {
  particularServices,
  afiliatedServices,
  statusHeadervalues,
  salesMembershipStatus,
  planStatus,
  planTypes,
  LANDING,
  PagoMovilArray,
} from './options'
import * as XLSX from 'xlsx'
import moment from 'moment'
import services from '../../images/services.svg'

export const convertRCVNameToId = (rcv, rcvOptions) => {
  let option = rcvOptions?.filter((r) => r?.name === rcv)
  let response = option[0]?.mongoId
  return response
}

export const renameMembreshipCrossStatus = (name) => {
  if (name === planStatus?.TO_BE_EXPIRED) {
    return 'Vencido'
  }
  if (name === planStatus?.EXPIRED) {
    return 'Rescate'
  } else {
    return name
  }
}

export const convertToHyphenatedLowerCase = (phrase) => {
  let url = phrase.toLowerCase().replace(/\s+/g, '-')
  return url
}

export const rename = (str) => {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString()
    }
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  }
}

export const currencyFormat = (value) => {
  if (!value && value != 0) {
    return ''
  }

  return '$' + value.toLocaleString('es')

  // value = Number(value).toFixed(2);
  // if (value < 0) {
  //   value = value * -1;
  //   return '-$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // } else {
  //   return '$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }
}

export const capitalize = (str) => {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString()
    }

    str = str?.toLowerCase()
    const arr = str.split(' ')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    let str2 = arr.join(' ')
    str2 = str2.replace(/rcv/gi, 'RCV')

    return str2
  } else return ''
}

export const NaNtoNumber = (value) => {
  if (!value) {
    return 0
  } else {
    return value
  }
}

export const translateStatus = (str) => {
  if (str === providerStatus.available) {
    return 'Disponible'
  }
  if (str === providerStatus.busy) {
    return 'Ocupado'
  } else {
    return ''
  }
}

export const exists = (str) => {
  if (str) {
    return str
  } else return ''
}

export function format(inputDate) {
  if (inputDate) {
    // expects Y-m-d
    var splitDate = inputDate.split('-')
    if (splitDate.count == 0) {
      return null
    }

    var year = splitDate[0]
    var month = splitDate[1]
    var day = splitDate[2]

    return day + '/' + month + '/' + year
  }
}

export const fixResponseJSON = async (response) => {
  let rullResp = await response.json()
  rullResp.status = response.status
  return rullResp
}

export const filterParams = (params) => {
  for (const key in params) {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === ''
    ) {
      delete params[key]
    }
  }
  return params
}

// Function that checks if the case time has expired or soon to be and sends a notification
export const checkAlert = (caso) => {
  let start = new Date(caso?.lastStage?.createdAt)
  let now = new Date()
  var diff = now.getTime() - start.getTime() // This will give difference in milliseconds
  diff = Math.round(diff / 60000)
  if (
    diff > parseInt(process.env.REACT_APP_ALERT_TIME) &&
    diff < parseInt(process.env.REACT_APP_WARNING_TIME)
  ) {
    notify(
      `${caso?.status}: ${caso?.caseId} de ${capitalize(
        caso?.membership?.customer?.firstName ||
          caso?.particularCustomer?.firstName
      )} ${capitalize(
        caso?.membership?.customer?.lastName ||
          caso?.particularCustomer?.lastName
      )} tiene ${diff} minutos sin ser atendida `,
      'success'
    )
    return 'reminder'
  }

  if (diff > parseInt(process.env.REACT_APP_WARNING_TIME)) {
    notify(
      `${caso?.status}: ${caso?.caseId} de ${capitalize(
        caso?.membership?.customer?.firstName ||
          caso?.particularCustomer?.firstName
      )} ${capitalize(
        caso?.membership?.customer?.lastName ||
          caso?.particularCustomer?.lastName
      )} tiene ${diff} minutos sin ser atendida `,
      'error'
    )
    return 'warning'
  }
}
export const formattedNumber = (x) => {
  return x.toFixed(2).replace(/[.,]00$/, '')
}

// Function that checks if the case time has expired or soon to be
export const checkCardTime = (caso) => {
  let start = new Date(caso?.lastStage?.createdAt)
  let now = new Date()
  var diff = now.getTime() - start.getTime() // This will give difference in milliseconds
  diff = Math.round(diff / 60000)
  if (
    diff > parseInt(process.env.REACT_APP_ALERT_TIME) &&
    diff < parseInt(process.env.REACT_APP_WARNING_TIME)
  ) {
    return 'reminder'
  }

  if (diff > parseInt(process.env.REACT_APP_WARNING_TIME)) {
    return 'warning'
  }
}
// Function that checks if the cases have expired or soon to be and paints the card
export let paintCard = (casos) => {
  let alert = ''

  for (let i = 0; i < casos?.length; i++) {
    if (checkCardTime(casos[i]) === 'reminder' && alert !== 'warning') {
      alert = checkCardTime(casos[i])
    }
    if (checkCardTime(casos[i]) === 'warning') {
      alert = checkCardTime(casos[i])
    }
  }
  return alert
}

export const checkFilterAndWarn = (casos, status) => {
  let filteredCases = casos?.filter((c) => c.status === status)
  for (let i = 0; i < filteredCases.length; i++) {
    checkAlert(filteredCases[i])
  }
  return filteredCases
}

export const serviceName = (code) => {
  if ([afiliatedServices.crane, particularServices.crane].includes(code)) {
    return 'Grúa'
  }
  if ([afiliatedServices.gas, particularServices.gas].includes(code)) {
    return 'Aux Gasol'
  }
  if ([afiliatedServices.accompaniment].includes(code)) {
    return 'AV'
  }
  if ([afiliatedServices.battery, particularServices.battery].includes(code)) {
    return 'Aux Bateria'
  }
  if ([afiliatedServices.tire, particularServices.tire2].includes(code)) {
    return 'Cambio Caucho'
  }
  if ([particularServices.tire1].includes(code)) {
    return 'Inflado Caucho'
  } else {
    return code
  }
}

export const reasignTime = (data, index) => {
  let totalTime = 0
  for (let j = 0; j < data?.newStages.length; j++) {
    if (data?.stages[j]?.name === data?.newStages[index]?.name) {
      return (data?.stages[j]?.totalTime / 60).toFixed(2) + ' min'
    }

    if (index === data?.newStages.length - 1) {
      for (let i = 0; i < data?.stages.length; i++) {
        if (statusHeadervalues.includes(data?.stages[i]?.name)) {
          totalTime = totalTime + data?.stages[i]?.totalTime
        }
      }
      totalTime = (totalTime / 60).toFixed(2)
      return totalTime > 0 ? totalTime + ' min' : ''
    }
  }
}

export const clearObject = (params) => {
  for (const key in params) {
    if (params[key] === undefined || params[key] == '') {
      delete params[key]
    }
  }
  return params
}

export const renameMembershipStatus = (status) => {
  let res = ''

  switch (status) {
    case salesMembershipStatus?.ACTIVO:
      res = 'Activa'
      break
    case salesMembershipStatus?.POR_RENOVACION:
      res = 'Por Renovación'
      break
    case salesMembershipStatus?.POR_VENCER:
      res = 'Vencidas (Período de Salva)'
      break
    case salesMembershipStatus?.VENCIDO:
      res = 'Perdidas'
      break
    case salesMembershipStatus?.ANULADO:
      res = 'Anulados'
      break
    case 'PENDIENTE DE APROBACION':
      res = 'Por Aprobacion'
      break
    default:
      res = status
      break
  }
  return res
}

export const filterAndMapPlans = (array) => {
  const filterArray = array.filter((obj, index) => {
    return index === array.findIndex((o) => obj.rcv === o.rcv)
  })
  return filterArray
}

export const getPrevMonth = (month, monthsBefore) => {
  month = month - monthsBefore
  if (month < 0) {
    month = month + 12
  }
  return month
}

export const getNextMonth = (month, monthsAfter) => {
  month = month + monthsAfter
  if (month > 11) {
    month = month - 12
  }
  return month
}

export const MethodValidation = (payments, fileUploaded, paymentMethods) => {
  let [method] = paymentMethods?.filter((p) =>
    [paymentMethodsEnum?.PUNTO_DE_VENTA, paymentMethodsEnum?.TDC]?.includes(
      p.name
    )
  )

  let PDVCounter = 0
  let regulayPayments = payments?.length
  for (let i = 0; i < payments?.length; i++) {
    if (payments[i].method === method?._id) PDVCounter = PDVCounter + 1
  }

  regulayPayments = regulayPayments - PDVCounter

  if (regulayPayments > 0 && regulayPayments !== fileUploaded?.length) {
    return true
  } else return false
}

export const checkMissingVehicleFields = (membership) => {
  const {
    address,
    brand,
    city,
    model,
    state,
    type,
    plate,
    year,
    municipality,
    class: clase,
  } = membership?.vehicle

  if (
    !brand ||
    !model ||
    !type ||
    !plate ||
    !year ||
    !state ||
    !city ||
    !address ||
    !municipality ||
    !clase
  ) {
    return '#ff6666'
  } else {
    return null
  }
}

export const returnMissingFields = (array, object) => {
  let missingFields = []

  for (let i = 0; i < array.length; i++) {
    if (
      !object.hasOwnProperty(array[i]?.label) ||
      object[array[i]?.label] === '' ||
      object[array[i]?.label] === null ||
      object[array[i]?.label] === undefined
    ) {
      missingFields.push(array[i])
    }
  }

  return missingFields
}

export const getCorrectDate = (date) => {
  let d = new Date(date)
  let utcDate = new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
    d.getUTCMilliseconds()
  )
  return utcDate
}

export const exportDataToExcel = (DATA, fileName) => {
  // Convert the data to CSV format using PapaParse
  let ws = XLSX.utils.json_to_sheet(DATA)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'sheet')
  XLSX.writeFile(wb, fileName)
}

export const filterSatesMotoriderAV = (states) => {
  states = states.filter((s) =>
    ['DISTRITO CAPITAL', 'MIRANDA', 'ANZOÁTEGUI', 'CARABOBO']?.includes(s?.name)
  )
  return states
}

export const removeBlankSpacesAndUppercase = (data, name, setValue) => {
  data = data.replace(/\s/g, '')
  setValue(name, data?.toUpperCase())
}

export const defineLangindType = (params, membership) => {
  if (
    [planTypes?.PLUSF4]?.includes(membership?.membershipPlan) &&
    ![
      planStatus?.PENDING_FOR_APPROBAL,
      planStatus?.TO_BE_RENEW,
      planStatus?.EXPIRED,
    ]?.includes(membership?.membershipStatus)
  ) {
    params.landing = LANDING?.PRIVATE
  }

  if (
    [planTypes?.PLUSF4, planTypes?.GOLDENP]?.includes(
      membership?.membershipPlan
    ) &&
    [
      planStatus?.ACTIVE,
      planStatus?.PENDING_FOR_APPROBAL,
      planStatus?.TO_BE_RENEW,
      planStatus?.EXPIRED,
      planStatus?.WAITING,
    ]?.includes(membership?.membershipStatus)
  ) {
    params.landing = LANDING?.MARKETING
  }

  if ([planTypes?.MOTORIDERAV]?.includes(membership?.membershipPlan)) {
    params.landing = LANDING?.PRIVATE
  }
  return params
}

export const greatedThanDate = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return d1.getTime() > d2.getTime()
}

export const validateIfOther = (watch, options) => {
  const other = options?.filter((o) => o?._id === watch('economicActivity'))
  if ([other[0]?.name]?.includes('Otro')) {
    return true
  } else return false
}

export const getMonthInSpanish = (date) => {
  if (!date) return null
  const fecha = new Date(date)
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]
  const monthIndex = fecha?.getMonth()
  return capitalize(months[monthIndex])
}

export const filterOption = (options, name) => {
  const [selected] = options?.filter((o) => o?.name === name)
  return selected || ''
}

export const displayChange = (data, param, source) => {
  if (validateIfDate(data?.[source])) {
    return moment(data?.[source]).format('DD/MM/YYYY')
  }

  if (typeof data?.[source] === 'boolean' && data?.[source]) {
    return data?.[source] ? 'Si' : 'No'
  }

  if (typeof data?.[source] === 'object' && data?.[source] !== null) {
    return <img src={services} style={{ cursor: 'pointer', height: '36px' }} />
  } else return data?.[source]
}

export const validateIfDate = (input) => {
  const dateRegex1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
  const dateRegex2 =
    /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(\w+ Time\)$/

  if (dateRegex1.test(input) || dateRegex2.test(input)) {
    return true
  } else {
    return false
  }
}

export const validateIfShowPM = (options, method) => {
  let filteredOptions = options?.filter((o) =>
    PagoMovilArray?.includes(o?.name)
  )
  if (filteredOptions.some((option) => option._id === method)) {
    return true
  } else {
    return false
  }
}
